import styled, { keyframes } from 'styled-components';
import { colors } from '@/themes/colorsMapping';

interface ILoading {
  size?: string;
}

const getSize = (size: string) => {
  if (size === 'large') {
    return 70;
  } else if (size === 'small') {
    return 30;
  }
  return 50;
};

const KeyFrames = keyframes`
  0% {
    transform: rotate(240deg);
  }
  100% {
    transform: rotate(600deg);
  }
`;

const slide = keyframes`  
  0%,
  100% {
    bottom: -35px;
  }
  25%,
  75% {
    bottom: -2px;
  }
  20%,
  80% {
    bottom: 2px;
  }
`;
const rotate = keyframes`
  0% {
    transform: rotate(-15deg);
  }
  25%,
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(25deg);
  }`;

const Loading = styled.div<ILoading>`
  width: ${(props) => (props.size ? getSize(props.size) : 64)}px;
  height: ${(props) => (props.size ? getSize(props.size) : 64)}px;
  // max-width: 3em;
  // max-height: 3em;
  // border-radius: 75%;
  // border-width: 4px;
  // background: linear-gradient(to top, #3300cc, #3300cc80) 100% 0/50% 100% no-repeat,
  //   linear-gradient(#3300cc80 50%, transparent 95%) 0 0/50% 100% no-repeat;
  // animation: 1s cubic-bezier(0.42, 0, 0.58, 1) infinite; // 1 second, ease-in-out
  // animation-name: ${KeyFrames};
  // mask: radial-gradient(farthest-side, transparent calc(100% - 4.5px), #000 calc(100% - 3.5px));

  position: relative;
  background: ${colors.white};
  border-radius: 4px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    transform: rotate(45deg) translate(30%, 40%);
    background: ${colors.blackText};
    box-shadow: 32px -34px 0 5px ${colors.purple};
    animation: ${slide} 2s infinite ease-in-out alternate;
  }

  &::after {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${colors.purple};
    transform: rotate(0deg);
    transform-origin: 35px 145px;
    animation: ${rotate} 2s infinite ease-in-out;
  }
`;

Loading.displayName = 'Loading';
export default Loading;
