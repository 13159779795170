import { Metafields, Product, ProductVariant } from '@/types/ecommerce.types';
import { getMetafieldV2, getPriceRange } from '@/utils/utils';
import getProductDescription from '@/components/product/shared/getProductDescription';

export const removeAllSchemaScripts = () => {
  const existingScripts = document.querySelectorAll('script[type="application/ld+json"]');

  existingScripts.forEach((script) => {
    try {
      const json = JSON.parse(script.innerHTML);
      if (json['@context'] === 'http://schema.org/') {
        script.remove();
      }
    } catch (e) {
      console.error('Error parsing JSON-LD script:', e);
    }
  });
};
export const addSchemaScript = async (
  product: Product,
  shopMetafields?: Metafields,
  currentVariant?: ProductVariant | null
) => {
  const currentSku = currentVariant?.sku;
  const reviewsMarkup = await fetchProductReviews(currentSku);
  const aggregateRating = reviewsMarkup.aggregateRating ?? null;
  const review = reviewsMarkup.review ?? null;
  const variants = product.variants.edges;

  // Set Images from Cloudinary
  let shoppingImages = [];
  shoppingImages.push(
    getMetafieldV2('image', product?.metafields),
    currentVariant?.image?.originalSrc
  );
  shoppingImages = shoppingImages.filter((x) => x);

  const currentModel = currentVariant?.model_file?.reference?.sources?.[0]?.url;
  const modelLink = getMetafieldV2('3d-model', product?.metafields);
  const logoLink = getMetafieldV2('logo', product?.metafields) || window.logo;
  const productMaterial = getMetafieldV2('material_of_product', product?.metafields);
  const { color, size } = getProductDescription(product, shopMetafields);
  const { lowPrice, highPrice } = getPriceRange(product);

  const shippingDetails = {
    '@type': 'OfferShippingDetails',
    shippingRate: {
      '@type': 'MonetaryAmount',
      value: 7.99,
      currency: 'USD',
    },
    shippingDestination: {
      '@type': 'DefinedRegion',
      addressCountry: 'US',
    },
    deliveryTime: {
      '@type': 'ShippingDeliveryTime',
      handlingTime: {
        '@type': 'QuantitativeValue',
        minValue: 1,
        maxValue: 2,
        unitCode: 'DAY',
      },
      transitTime: {
        '@type': 'QuantitativeValue',
        minValue: 3,
        maxValue: 5,
        unitCode: 'DAY',
      },
    },
  };

  // Set Offers object
  const offer = {
    '@type': 'Offer',
    url: window.location.href,
    itemCondition: 'https://schema.org/NewCondition',
    availability: `https://schema.org/${
      currentVariant?.availableForSale ? 'InStock' : 'OutOfStock'
    }`,
    price: currentVariant?.price?.amount,
    priceCurrency: currentVariant?.price?.currencyCode,
    shippingDetails,
    hasMerchantReturnPolicy: {
      '@type': 'MerchantReturnPolicy',
      applicableCountry: 'US',
      returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
      merchantReturnDays: 30,
      returnMethod: 'https://schema.org/ReturnByMail',
      returnFees: 'https://schema.org/FreeReturn',
    },
  };

  const aggregateOffer = {
    '@type': 'AggregateOffer',
    offerCount: variants.length,
    lowPrice: lowPrice,
    highPrice: highPrice,
    priceCurrency: currentVariant?.price?.currencyCode,
  };

  // Build Final Product Markup
  const productMarkup: any = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    brand: {
      '@type': 'Brand',
      name: product.vendor,
    },
    name: product.title,
    image: shoppingImages,
    description: product.description,
    sku: currentSku,
    mpn: currentSku,
    offers: [aggregateOffer, offer],
  };

  if (size) {
    productMarkup.size = size;
  }

  if (color) {
    productMarkup.color = color;
  }

  if (logoLink) {
    productMarkup.logo = logoLink;
  }

  if (productMaterial) {
    productMarkup.material = productMaterial;
  }

  if (modelLink || currentModel) {
    productMarkup.subjectOf = {
      '@type': '3DModel',
      encoding: {
        '@type': 'MediaObject',
        contentUrl: currentModel ? currentModel : modelLink,
      },
    };
  }

  // Set Ratings and Reviews if present
  if (aggregateRating) {
    productMarkup.aggregateRating = aggregateRating;
  }
  if (review) {
    productMarkup.review = review;
  }

  console.log(productMarkup);

  const script = document.createElement('script');
  script.type = 'application/ld+json';
  script.innerHTML = JSON.stringify(productMarkup);

  document.body.appendChild(script);
};

const fetchProductReviews = async (sku?: string) => {
  if (!sku) {
    return;
  }

  return fetch(
    'https://api.reviews.io/json-ld/product/richsnippet?store=www.frameology.com&sku=' +
      sku +
      '&data=true',
    {
      method: 'GET',
    }
  )
    .then((res) => res.text())
    .then((text) => {
      try {
        return JSON.parse(text.replace(/(,\s*?})$/gm, '}'));
      } catch (e) {
        console.error(e);
        return null;
      }
    });
};
