import localforage from 'localforage';
import axios from 'axios';
import { TileObj, Validation } from '../store/upload/uploadSlice';
import { safeParser, debug } from '../utils/utils';

export type ImageSize = {
  width: number;
  height: number;
};

export type Tile = {
  fetching?: false;
  createdAt: number;
  publicId: string;
  remoteUrl: string;
  position: any;
  loading: boolean;
  isTransforming: boolean;
  blob: string;
  crop?: any;
  cropped?: string;
  blobIsValid?: boolean;
  originalImageSize?: ImageSize | undefined;
  reducedImageSize?: ImageSize | undefined;
  uploading?: boolean;
  shouldCrop: boolean;
  frameIsLoading?: boolean;
  validation?: Validation;
  orientation?: string;
};

const tilesKey = 'tiles';

const DataService = {
  readTiles: function (): Tile[] {
    const tiles = safeParser(localStorage.getItem(tilesKey), {}) as Tile[];
    return tiles;
  },

  saveTiles: function (data: any) {
    localStorage.setItem(tilesKey, JSON.stringify(data));
  },

  getTiles: function (): TileObj {
    const tilesParsed: TileObj = safeParser(localStorage.getItem(tilesKey), {});

    // Set blobIsValid to false to all tiles when loading the tiles (browser refresh)
    if (Object.keys(tilesParsed).length) {
      const keys = Object.keys(tilesParsed);
      keys.forEach((key: any) => {
        tilesParsed[key].isTransforming = false;
        tilesParsed[key].blobIsValid = false;
        tilesParsed[key].shouldCrop = true;
        tilesParsed[key].uploading = false;
      });
    }
    return tilesParsed;
  },

  saveImageOnStorage: function (payload: any) {
    const tiles = localStorage.getItem('tiles');
    const tilesObj: { [key: number]: Tile } = tiles === null ? {} : JSON.parse(tiles);
    if (payload) {
      tilesObj[payload.position] = payload;
    }

    localStorage.setItem('tiles', JSON.stringify(tilesObj));
  },

  getLastUploadedImage: function (): Tile | undefined {
    const parsedTiles = this.readTiles();
    const sortedTiles = Object.keys(this.readTiles())
      .map((key: any) => parsedTiles[key])
      .filter((tile): tile is Tile => !!tile?.createdAt)
      .sort((a: Tile, b: Tile) => a.createdAt - b.createdAt)
      .reverse();
    debug('DataService.getLastUploadedImage', sortedTiles, 'j');
    return sortedTiles.length > 0 ? sortedTiles[0] : undefined;
  },

  getImageByPosition: async function (position: any, tiles?: any) {
    const tile: Tile = this.readTiles()[position];
    return new Promise<Blob>(async (resolve, reject) => {
      let blobResponse: Blob | null | undefined;

      // 1. FETCH: Fetch by blob field
      try {
        const resp = await axios.get(tile?.blob, {
          responseType: 'blob',
        });
        blobResponse = resp.data;
      } catch (err) {
        console.log('blob error1', err);
      }

      // 2. FETCH: Fetch by localForage
      if (!blobResponse) {
        try {
          blobResponse = await localforage.getItem(String(tile?.position));
        } catch (err) {
          console.log('blob error2', err);
        }
      }

      // 3. FETCH: Fetch remoteUrl
      if (!blobResponse) {
        try {
          const resp = await axios.get(tile?.remoteUrl, {
            responseType: 'blob',
          });
          blobResponse = resp.data;
        } catch (err) {
          console.log('blob error3', err);
        }
      }

      if (blobResponse) {
        resolve(blobResponse);
      } else {
        reject();
        console.log('error.............');
      }
    });
  },

  saveLocalForage: async function ({ imageId, file }: { imageId: string; file: Blob }) {
    return localforage.setItem(imageId, file).catch(() => {});
  },
  removeLocalForage: async function (publicId: string) {
    return localforage.removeItem(publicId).catch(() => {});
  },
};

export default DataService;
