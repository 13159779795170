import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScript from '@/hooks/useScript';
import useSetupPartner from '@/hooks/useSetupPartner';
import useSetupArtisticUrls from '@/hooks/useSetupArtisticUrls';
import * as S from './ProductLP.styles';
import { ProductFaqStyled } from './ProductLP.styles';
import { ProductTypes, RouteProps } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { setShouldCrop } from '@/store/editor/editorSlice';
import { setTiles } from '@/store/upload/uploadSlice';
import DataService from '@/services/DataService';
import TrackingService from '@/services/TrackingService';
import {
  getCurrentVariant,
  getFrameSizeFromVariant,
  getMetafield,
  getMetafieldV2,
  getQueryParams,
} from '@/utils/utils';
import { removeArtisticTiles } from '../shared/artistic/removeArtisticTiles';
import Cart from '../cart/Cart';
import ProductCta from '../cart/ProductCta';
import Reviews from '../reviews/Reviews';
import Resolution from '../dialog/Resolution';
import ProductOptions from '../options/MatOptions';
import Performance from '../upload/Performance';
import Header from '@/components/product/productLP/Header';
import UploadInput from '@/components/product/upload/UploadInput';
import { useMediaQuery } from 'react-responsive';
import { ProductOptionsTitle } from '@/components/product/options/ProductOptions.styles';
import FloatingCta from '@/components/product/cart/FloatingCta';
import Selector from '@/components/product/options/Selector';
import { SectionTypes } from '@/components/product/types';
import ProductCollections from '@/components/product/options/ProductCollections';
import PressSection from '@/components/product/press/PressSection';
import InteractiveViewSection from '@/components/product/interactiveView/InteractiveViewSection';
import ModalDiscountPopup from '@/components/product/dialog/ModalDiscountPopup';
import Footer from '@/components/product/productLP/Footer';
import ProductVariants from '@/components/product/options/ProductVariants';
import { isPopupShown } from '@/utils/discountPopup';
import ProductReviewMock from '@/components/product/shared/ProductReviewMock';
import ProductDescription from '@/components/product/description/ProductDescription';
import { removeAllSchemaScripts, addSchemaScript } from '@/utils/schema';
import Product from '..';
import ProductSwatches from '../options/ProductSwatches';

const ProductLP = ({ product, handle, shopMetafields }: RouteProps) => {
  // Load reviews.io scripts
  useScript('https://widget.reviews.io/polaris/build.js');
  useSetupPartner();
  useSetupArtisticUrls(product);

  const [shoudReInit, setShouldReInit] = useState(false);
  const [actionTab, setActionTab] = useState<SectionTypes | null>(
    getQueryParams('style') === 'open' ? SectionTypes.STYLE : null
  );
  const { type } = useSelector((state: RootState) => state.product);
  const dispatch = useDispatch();
  const uploadRef = useRef<HTMLInputElement>(null!);
  const isMobile = useMediaQuery({ maxWidth: 799 });
  const { tiles } = useSelector((state: RootState) => state.upload);
  const currentVariant = getCurrentVariant(product?.variants?.edges);
  const frameSizeInPixels = getFrameSizeFromVariant(currentVariant);
  const canBeRotated = !!getMetafield('canBeRotated', product?.metafields);
  const maxSize = Number(getMetafieldV2('max_upload_size_mb', shopMetafields) ?? '100');
  const navHeader = document.getElementById('fn-main-header');
  const mainContent = document.getElementById('MainContent');
  const pageName = window.pageHandle;
  navHeader?.classList.toggle('gallery-header', type === ProductTypes.GALLERY);

  if (mainContent) {
    mainContent.dataset.prodtype = String(type);
    mainContent.dataset.handle = String(handle);
  }

  useEffect(() => {
    DataService.saveTiles(tiles);
    setShouldReInit(!shoudReInit);
    setTimeout(() => {
      setShouldReInit(!shoudReInit);
    }, 10);
  }, [tiles]);

  useEffect(() => {
    if (handle) {
      const tilesWithoutArtistic = removeArtisticTiles(tiles);
      dispatch(setTiles(tilesWithoutArtistic));
      //window.scrollTo({ top: 0, behavior: "smooth" });
      dispatch(setShouldCrop(true));
    }
  }, [handle]);

  // Track a single view_item event whenever the productVariant changes
  useEffect(() => {
    if (product) {
      TrackingService.ga4Track('view_item', product);
      removeAllSchemaScripts();
      addSchemaScript(product, shopMetafields, currentVariant);
    }

    document.title = product?.seo?.title?.toString() || 'Frameology: Print and Frame Photos Online';
  }, [product, currentVariant]);

  const onUploadPhoto = (e: React.MouseEvent) => {
    e.preventDefault();
    uploadRef.current?.click();
  };

  const onCloseSection = () => setActionTab(null);

  const getProductSelector = (
    <>
      {isMobile && (
        <>
          <Selector
            onClose={onCloseSection}
            title={'Choose style'}
            open={actionTab === SectionTypes.STYLE}
          >
            <>
              <ProductCollections product={product} />
              <S.ProductSwatchesStyled pagePath={`/pages/${pageName}`} showDefaultColor />
            </>
          </Selector>
          <Selector
            onClose={onCloseSection}
            title={'Choose color'}
            open={actionTab === SectionTypes.COLOR}
          >
            <S.ProductSwatchesStyled pagePath={`/pages/${pageName}`} showDefaultColor openStyle />
          </Selector>
          <Selector
            onClose={onCloseSection}
            title={'Choose size'}
            open={actionTab === SectionTypes.SIZE}
          >
            <ProductVariants showMeta={false} />
          </Selector>

          <Cart>
            <FloatingCta product={product} fixed onUploadPhoto={onUploadPhoto} />
          </Cart>
        </>
      )}
      {!isMobile && (
        <div className="tmplt-product__content-wrap" style={{ zIndex: 6 }}>
          <S.ProductHeaderWithSize showSize />
          <ProductReviewMock />
          <Cart>
            <S.OptionsWrapper className="tmplt-product__options-wrapper">
              <S.OptionsBlock className="fn-product-options tmplt-product__options tmplt-product__options--opened">
                <ProductOptionsTitle>Choose size</ProductOptionsTitle>
                <ProductVariants showMeta={false} />
                <ProductOptionsTitle>Choose style</ProductOptionsTitle>
                <ProductCollections product={product} />
                <S.ProductSwatchesStyled pagePath={`/pages/${pageName}`} showDefaultColor />
              </S.OptionsBlock>
              <ProductOptions />
            </S.OptionsWrapper>
            <ProductCta onUploadPhoto={onUploadPhoto} />
          </Cart>
        </div>
      )}
    </>
  );

  return (
    <>
      <Header product={product} />
      <S.BuilderWrapper className="tmplt-product">
        <S.MediaWrapper
          className="tmplt-product__media-wrapper"
          data-prodtype={type}
          data-handle={handle}
        >
          <S.FrameBuilderStyled
            product={product}
            shopMetafields={shopMetafields}
            frameSizes={frameSizeInPixels}
            variant={currentVariant}
            setActionTab={setActionTab}
            textARButton={isMobile ? 'AR' : 'View in your room'}
            showStyleButton
          />
        </S.MediaWrapper>
        <UploadInput
          position="0"
          ref={uploadRef}
          canBeRotated={canBeRotated}
          maxSize={maxSize}
          frameSizeInPixels={frameSizeInPixels}
          onUpload={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
        />
        {getProductSelector}
      </S.BuilderWrapper>
      <PressSection />
      <InteractiveViewSection />
      <ProductDescription />
      <ProductFaqStyled />
      <Reviews />
      <Performance product={product} />
      <Footer />
      <Resolution />
      <ModalDiscountPopup
        product={product}
        minutes={10}
        showCountdownTimer={false}
        showDiscountPopup={isPopupShown()}
      />
    </>
  );
};
export default ProductLP;
