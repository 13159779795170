import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import type { ModelViewerElement } from '@google/model-viewer/lib/model-viewer';
import { Metafields, Product, ProductTypes, ProductVariant } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { FrameSizes, getMetafield, getMetafieldV2, getModel, mapProductEdges } from '@/utils/utils';
import TrackingService from '@/services/TrackingService';
import ARIcon from '../../templates/icons/ARIcon';
import Viewer from '../viewer/Viewer';
import Editor from '../editor/Editor';
import SizeIcon from '../../templates/icons/SizeIcon';
import FrameIcon from '../../templates/icons/FrameIcon';
import { Container, ModelViewerWrapper, ProductContainer, Wrapper } from './FrameBuilder.styles';
import ArQrCode from '../slider/ArQrCode';
import ModelViewer from '../slider/ModelViewer';
import withDefaultModelPosition from '@/utils/withDefaultModelPosition';
import { SectionTypes } from '../types';
import FakeEditor from '@/components/product/editor/FakeEditor';
import BottomButtons, { Buttons } from '@/components/product/frameBuilder/BottomButtons';
import UploadInput from '@/components/product/upload/UploadInput';
import { setTo } from '@/store/performance/performanceSlice';
import ProductInfoV2 from '../header/ProductInfoV2';
import ColorIcon from '@/components/templates/icons/ColorIcon';
import ProductHeaderV2 from '../header/ProductHeaderV2';
import EditIconV2 from '@/components/templates/icons/EditIconV2';
import { Collection } from 'shopify-storefront-api-typings';
import { Color } from '../options/ProductSwatches';
import UploadInputs, { UploadInputsImperative } from '../upload/UploadInputs';
import { useModelPosition } from '../slider/useModelPosition';
import { setOrientation } from '@/store/viewer/viewerSlice';
import { setItemOrientation } from '@/store/product/productSlice';

const HiddenFrames = styled.div`
  position: absolute;
  top: -10000px;
  left: -10000px;
`;

declare global {
  interface Window {
    enable3DBuilder?: boolean;
  }
}

const SmallIcon = styled(ARIcon)`
  width: 23px;
  height: 23px;
`;

type PropsType = {
  product: Product;
  shopMetafields?: Metafields;
  frameSizes: FrameSizes;
  variant?: ProductVariant | null;
  textARButton?: string;
  setActionTab?: (tab: SectionTypes | null) => void;
  className?: string;
  showProductInfo?: boolean;
  colorCollection?: Collection;
  showStyleButton?: boolean;
};

const FrameBuilder = ({
  className,
  product,
  shopMetafields,
  frameSizes,
  variant,
  setActionTab,
  colorCollection,
  showProductInfo = true,
  textARButton = '',
  showStyleButton = false,
}: PropsType) => {
  const colors: Color[] = mapProductEdges(colorCollection as Collection);
  const { imageId, isEditingMode } = useSelector((state: RootState) => state.editor);
  const { type } = useSelector((state: RootState) => state.product);
  const tiles = useSelector((state: RootState) => state.upload.tiles);
  const { frameOrientation } = useSelector((state: RootState) => state.viewer);
  const dispatch = useDispatch();
  const tile = tiles?.[0];
  const [userImg, setUserImg] = useState(tile?.cropped);
  const [showQR, setShowQR] = useState(false);
  const [isAnimationEnabled] = useState(false);
  const uploadRef = useRef<HTMLInputElement>(null!);
  const arViewer = useRef<ModelViewerElement>(null!);
  const isGallery = type === ProductTypes.GALLERY;
  const isMobile = useMediaQuery({ maxWidth: 799 });
  const arBuilderEnabled =
    getMetafieldV2('3d_builder_enabled', product?.metafields) === 'true' ||
    window.enable3DBuilder === true;
  const modelUrl = getModel(variant?.model_file?.reference);
  const canBeRotated = !!getMetafield('canBeRotated', product?.metafields);
  const maxSize = Number(getMetafieldV2('max_upload_size_mb', shopMetafields) ?? '100');
  const is3dModel = variant && modelUrl && arBuilderEnabled;
  const galleryBackgroundImage =
    getMetafieldV2('gallery_background_image', product?.metafields) ?? '';
  const modelPosition = useModelPosition(variant?.metafields);
  const uploader = useRef<UploadInputsImperative>(null);
  const tilesNumber =
    parseInt(getMetafieldV2('gallery_item_count', product?.metafields) ?? '') || 1;

  useEffect(() => {
    setUserImg((prevState) => {
      if (tile?.isTransforming || tile?.loading) return prevState;
      if (prevState !== tile?.cropped) return tile?.cropped;
      return prevState;
    });

    if (tile) dispatch(setTo(performance.now()));
  }, [tile]);

  useEffect(() => {
    const header = document.getElementById('shopify-section-header');
    if (!header) return;

    header.style.display = isEditingMode && isMobile ? 'none' : 'block';

    return () => {
      header.style.display = 'block';
    };
  }, [isEditingMode, isMobile]);

  useEffect(() => {
    setShowQR(false);
  }, [variant]);

  const onUploadEdit = (position?: number) => {
    uploader.current?.upload(position ?? 0);
  };

  const activateAr = async () => {
    const modelViewer = arViewer.current;
    TrackingService.ga4Track('activate_ar', product, 1, {
      extraAttributes: { arType: modelViewer.canActivateAR ? 'mobile' : 'desktop' },
    });

    if (!modelViewer) return;

    if (!modelViewer.canActivateAR) {
      setShowQR(true);
    }

    await withDefaultModelPosition(modelViewer, async () => {
      modelViewer.activateAR();
    });
  };

  const bottomButtons: Buttons[] = useMemo(() => {
    const leftButtons: Buttons[] = [];
    const rightButtons: Buttons[] = [];
    const buttons: Buttons[] = [
      {
        onClick: () => setActionTab?.(SectionTypes.SIZE),
        imageComponent: <SizeIcon />,
        content: SectionTypes.SIZE,
        hideDesktop: true,
      },
    ];

    if (isMobile && showStyleButton) {
      buttons.push({
        onClick: () => setActionTab?.(SectionTypes.STYLE),
        imageComponent: <FrameIcon />,
        content: SectionTypes.STYLE,
      });
    }

    if (colors.length > 1) {
      buttons.push({
        onClick: () => setActionTab?.(SectionTypes.COLOR),
        imageComponent: <ColorIcon />,
        content: SectionTypes.COLOR,
        hideDesktop: true,
      });
    }

    if (product?.mat_variants) {
      buttons.push({
        onClick: () => setActionTab?.(SectionTypes.MAT),
        imageComponent: <FrameIcon />,
        content: SectionTypes.MAT,
        hideDesktop: true,
      });
    }

    if (variant?.model_file) {
      rightButtons.push({
        onClick: activateAr,
        content: textARButton,
        imageComponent: <SmallIcon />,
        tooltipText: 'View on your wall',
      });
    }

    if (userImg && !isGallery) {
      leftButtons.unshift({
        onClick: onUploadEdit,
        content: 'Edit',
        imageComponent: <EditIconV2 />,
      });
    }

    return {
      leftButtons,
      rightButtons,
      mainButtons: buttons,
    };
  }, [userImg, variant?.model_file, isMobile, colorCollection]);

  useEffect(() => {
    if (!canBeRotated && frameOrientation === 'landscape') {
      dispatch(setOrientation('portrait'));
      dispatch(
        setItemOrientation({
          position: imageId,
          orientation: 'portrait',
        })
      );
    }
  }, [product]);

  if (!product) return null;
  return (
    <Container
      className={className}
      isEditingMode={isEditingMode}
      isGallery={isGallery}
      id3dModel={is3dModel}
      showStyleButton={showStyleButton}
    >
      <Wrapper className="tmplt-product__media tmplt-product__media--bone" isGallery={isGallery}>
        <Editor />
        {showQR && (
          <ArQrCode
            variant={variant}
            onClose={() => {
              setShowQR(false);
            }}
          />
        )}
        {variant && (
          <ModelViewerWrapper isGallery={isGallery}>
            {!arBuilderEnabled && <Viewer />}
            {modelUrl && (
              <ModelViewer
                ref={arViewer}
                modelUrl={modelUrl}
                variant={variant}
                isGallery={isGallery}
                isEditor={true}
                onActivateAr={activateAr}
                animation={isAnimationEnabled}
                onUploadPhoto={onUploadEdit}
                cameraControls={!isGallery}
                backgroundImage={galleryBackgroundImage}
                modelPosition={modelPosition}
              />
            )}
          </ModelViewerWrapper>
        )}
        {/*
          The purposee of this is to render frames that facilitates resizing and inline preview.
          Non gallery AR builder is using view in the room for that purpose
        */}
        {isGallery && arBuilderEnabled && (
          <HiddenFrames id="viewer-wrapper">
            <Viewer isRoomMode={false} />
          </HiddenFrames>
        )}

        {product && tile && tile.shouldCrop && <FakeEditor position={'0'} />}
        {!isGallery && (
          <UploadInput
            position="0"
            ref={uploadRef}
            canBeRotated={canBeRotated}
            maxSize={maxSize}
            frameSizeInPixels={frameSizes}
          />
        )}
        {showProductInfo && !isGallery ? (
          <ProductContainer>
            <ProductHeaderV2 />
            <ProductInfoV2 />
            {/* <ProductReviewMockStyled /> */}
          </ProductContainer>
        ) : null}
        <UploadInputs
          tiles={tilesNumber}
          canBeRotated={canBeRotated}
          maxSize={maxSize}
          frameSizeInPixels={frameSizes}
          ref={uploader}
          onUpload={() => {}}
          onEditorOpen={() => {
            TrackingService.ga4Track('open_editor', product, 1);
          }}
        />
        {isMobile && isGallery ? null : (
          <BottomButtons
            className="frame-builder-bottom-container"
            buttons={bottomButtons}
            product={product}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default FrameBuilder;
